angular.module("app")
    .controller("bookingsController", ["$rootScope", "$scope", "$log", "$location", "$window", "$timeout", "Notification", "bookingService", "$state", function ($rootScope, $scope, $log, $location, $window, $timeout, Notification, bookingService, $state) {
        var FIFTEEN_SECONDS = 15 * 1000;
        var vm = this;
        vm.pageNotReady = true;
        vm.loading = false;
        vm.loadingEnroute = false;
        vm.activeBookings = undefined;
        vm.recentBookings = undefined;
        vm.defaultLocation = undefined;
        vm.errorMessage = undefined;

        vm.serverError = undefined;

        var pollTimer;
        $scope.messageDriverDialog = undefined;

        (function init() {
            $log.debug("initialised bookings controller");
            bindLoaderStateEvents();
            getDefaultLocation();
            poll();
        })();

        vm.hasNoActiveBookings = function () {
            return _.isEmpty(vm.activeBookings);
        };

        vm.hasNoRecentBookings = function () {
            return _.isEmpty(vm.recentBookings);
        };

        vm.updateDefaultLocation = function () {
            $log.debug("updating default location");
            bookingService.setDefaultLocation(vm.defaultLocation);
        };

        vm.removeBooking = function (booking) {
            var bookingToRemove = _.find(vm.activeBookings, function (trip) {
                return trip.bookingReference === booking.bookingReference;
            });
            _.remove(vm.bookings, bookingToRemove);
        };

        function getDefaultLocation() {
            return bookingService.getDefaultLocation().then(function (location) {
                vm.defaultLocation = location;
            });
        }

        function poll() {
            bookingService.listActiveBookings().then(function (data) {
                vm.errorMessage = undefined;
                vm.activeBookings = data;
                bookingService.listRecentBookings().then(function (data) {

                    vm.serverError = undefined;

                    var bookingIds = _.map(vm.bookings, "bookingReference");
                    vm.recentBookings = _.filter(data, function (booking) {
                        if (!_.includes(bookingIds, booking.bookingReference)) {
                            booking.booked = moment(booking.booked);

                            var timezoneOffset = moment.parseZone(booking.requestedPickup).utcOffset();
                            booking.requestedPickup = moment(booking.requestedPickup).utcOffset(timezoneOffset).format('YYYY/MM/DD hh:mm');

                            return true;
                        }
                    });
                }).catch(function (error) {
                    vm.serverError = error.data;
                })['finally'](function () {
                    vm.pageNotReady = false;
                    setPollTimeout();
                });
            }).catch(function (error) {
                $log.debug("Error listing bookings: " + error.data);
                vm.errorMessage = "Unable to obtain a list of current bookings";
                vm.pageNotReady = false;
                setPollTimeout();
            });
        }

        function setPollTimeout() {
            cancelPollTimeout();
            pollTimer = $timeout(poll, FIFTEEN_SECONDS);
        }

        function cancelPollTimeout() {
            if (pollTimer) $timeout.cancel(pollTimer);
        }

        function bindLoaderStateEvents() {
            $scope.$on('loader_show', function () {
                vm.loading = true;
            });

            $scope.$on('loader_hide', function () {
                vm.loading = false;
            });

            $rootScope.$on('$locationChangeSuccess', function () {
                if ($state.current.url !== "/bookings") {
                    cancelPollTimeout();
                }
            });
        }

    }]);
